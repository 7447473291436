import {
  useQuery as useQueryOrigin,
  UseQueryOptions,
  UseQueryResult
} from "@tanstack/react-query";
import { AxiosError } from "axios";
export declare type QueryKey = readonly unknown[];

export function useQuery<TResult>(
  queryKey: QueryKey,
  queryFn: (parmas?: unknown) => Promise<TResult>,
  options: UseQueryOptions<TResult, unknown> = {}
): UseQueryResult<TResult, AxiosError> {
  return useQueryOrigin<TResult, AxiosError>(queryKey, queryFn, {
    suspense: true,
    ...options,
    onError: (err) => {
      console.log(err);
    }
  });
}

export default useQuery;
