import axios from "axios";

import { settings } from "@/configs";

const instance = axios.create({
  baseURL: settings.API_DOMAIN
});

instance.interceptors.request.use(
  async function (config) {
    let appToken = "";
    if (typeof window === "undefined") {
      const { cookies: serverCookies } = await import("next/headers");
      appToken = serverCookies().get("access_token")?.value;
    } else {
      const { Cookies } = await import("react-cookie");
      const clientCookies = new Cookies();
      appToken = clientCookies.get("access_token");
    }
    if (appToken) {
      config.headers.Authorization = `Bearer ${appToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
