"use client";

import Image from "next/image";
import Script from "next/script";

import { kako_settings, settings } from "@/configs";
import { Typography } from "design-system-ui";
import Link from "next/link";
import { RightArrowIcon } from "./RightArrowIcon";

export const KaKaoSignUpButton = () => {
  const initKakao = () => {
    window.Kakao?.init(kako_settings.KAKAO_JAVASCRIPT_KEY);
  };

  const authorizeKakao = () => {
    window.Kakao?.Auth?.authorize({
      scope: "phone_number",
      redirectUri: `${settings.DOMAIN}${kako_settings.KAKAO_REDIRECT_URI_PATH}`
    });
  };

  return (
    <div className="tw-absolute tw-bottom-[95px] tw-left-0 tw-w-full tw-px-[35px] tw-flex tw-flex-col">
      <Script
        src="https://t1.kakaocdn.net/kakao_js_sdk/2.3.0/kakao.min.js"
        integrity="sha384-70k0rrouSYPWJt7q9rSTKpiTfX6USlMYjZUtr1Du+9o4cGvhPAWxngdtVZDdErlh"
        crossOrigin="anonymous"
        onLoad={initKakao}
      ></Script>
      <div className="tw-flex">
        <div
          style={{ backgroundColor: "#FEE500" }}
          className="tw-flex tw-items-center tw-justify-center tw-px-[20px] tw-py-[14px] tw-text-gray700 tw-rounded-[5px] tw-cursor-pointer tw-text-title3 tw-m-auto tw-grow"
          onClick={authorizeKakao}
        >
          <Image
            src="/images/icon/kakao.png"
            width={24}
            height={24}
            alt="카카오로고"
          />
          <span className="tw-ml-[4px]">카카오로 3초만에 시작하기</span>
        </div>
      </div>
      <Link
        href={settings.SCAN_DEEPLINK_URL}
        className="tw-text-center tw-no-underline tw-mt-18"
      >
        <Typography
          variant="title3"
          color="gray700"
          className="tw-flex tw-aligns-center tw-justify-center"
        >
          <span className="tw-leading-5">앱 설치하러가기</span>
          <RightArrowIcon width={20} height={20} />
        </Typography>
      </Link>
      <p id="token-result"></p>
    </div>
  );
};
